import React, { useContext, useEffect, useState } from "react";
import LazyLoad from "react-lazyload";
import Skeleton from "react-loading-skeleton";
import PageWrapper from "../components/PageWrapper";
import GlobalContext from "../context/GlobalContext";
import { AuthContext } from "../context/AuthContext";
import { getBalance } from "../utils/utilities";
import notify from "../utils/notify";

import NearIcon from "../assets/image/svg/near_icon.svg";

const DashboardAdminWallet = () => {
  const gContext = useContext(GlobalContext);
  const [loading, setLoading] = useState(true);
  const [balance, setBalance] = useState(null);
  const { isAuthenticated } = useContext(AuthContext);

  const fetchWalletBalance = async () => {
    try {
      const auth = await isAuthenticated();
      const walletBalance = await getBalance(auth?.admin?.account_id);
      setBalance(walletBalance);
      setLoading(false);
    } catch (error) {
      notify(error.message);
      console.log(error?.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchWalletBalance();
    return () => setLoading(false);
  }, []);

  return (
    <>
      <PageWrapper
        headerConfig={{
          button: "profile",
          isFluid: true,
          bgClass: "bg-default",
          reveal: false,
        }}
      >
        <div className="dashboard-main-container mt-25 mt-lg-31">
          <div className="container">
            <div className="row mb-7">
              <div className="col-xxl-3 col-xl-4 col-lg-6 col-sm-6 d-flex justify-content-center">
                {loading ? (
                  <Skeleton width={300} height={120} />
                ) : (
                  <button
                    className="media bg-white rounded-4 pl-8 pt-9 pb-9 pr-7 hover-shadow-1 mb-9 shadow-8 border-0 focus-reset wallet-badge"
                    onClick={(e) => e.preventDefault()}
                  >
                    <div className="bg-gray-opacity-1 circle-56 mr-7">
                      <img src={NearIcon} alt="near icon" height={56} />
                    </div>
                    <div className="">
                      <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">
                        <LazyLoad>
                          <span className="counter">
                            {balance?.total}
                          </span>
                        </LazyLoad>
                      </h5>
                      <p className="font-size-4 font-weight-normal text-gray mb-0 mt-6">
                        Wallet balance
                      </p>
                    </div>
                  </button>
                )}
              </div>

              <div className="col-xxl-3 col-xl-4 col-lg-6 col-sm-6 d-flex justify-content-center">
                {loading ? (
                  <Skeleton width={300} height={120} />
                ) : (
                  <button
                    className="media bg-white rounded-4 pl-8 pt-9 pb-9 pr-7 hover-shadow-1 mb-9 shadow-8 border-0 focus-reset wallet-badge"
                    onClick={(e) => e.preventDefault()}
                  >
                    <div className="bg-gray-opacity-1 circle-56 mr-7">
                      <img src={NearIcon} alt="near icon" height={56} />
                    </div>
                    <div className="">
                      <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">
                        <LazyLoad>
                          <span className="counter">
                            {balance?.stateStaked}
                          </span>
                        </LazyLoad>
                      </h5>
                      <p className="font-size-4 font-weight-normal text-gray mb-0 mt-6">
                        Reserved for storage
                      </p>
                    </div>
                  </button>
                )}
              </div>

              <div className="col-xxl-3 col-xl-4 col-lg-6 col-sm-6 d-flex justify-content-center">
                {loading ? (
                  <Skeleton width={300} height={120} />
                ) : (
                  <button
                    className="media bg-white rounded-4 pl-8 pt-9 pb-9 pr-7 hover-shadow-1 mb-9 shadow-8 border-0 focus-reset wallet-badge"
                    onClick={(e) => e.preventDefault()}
                  >
                    <div className="bg-gray-opacity-1 circle-56 mr-7">
                      <img src={NearIcon} alt="near icon" height={56} />
                    </div>
                    <div className="">
                      <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">
                        <LazyLoad>
                          <span className="counter">
                            {balance?.staked}
                          </span>
                        </LazyLoad>
                      </h5>
                      <p className="font-size-4 font-weight-normal text-gray mb-0 mt-6 text-nowrap">
                        Reserved for transactions
                      </p>
                    </div>
                  </button>
                )}
              </div>

              <div className="col-xxl-3 col-xl-4 col-lg-6 col-sm-6 d-flex justify-content-center">
                {loading ? (
                  <Skeleton width={300} height={120} />
                ) : (
                  <button
                    className="media bg-white rounded-4 pl-8 pt-9 pb-9 pr-7 hover-shadow-1 mb-9 shadow-8 border-0 focus-reset wallet-badge"
                    onClick={(e) => e.preventDefault()}
                  >
                    <div className="bg-gray-opacity-1 circle-56 mr-7">
                      <img src={NearIcon} alt="near icon" height={56} />
                    </div>
                    <div className="">
                      <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">
                        <LazyLoad>
                          <span className="counter">
                            {balance?.available}
                          </span>
                        </LazyLoad>
                      </h5>
                      <p className="font-size-4 font-weight-normal text-gray mb-0 mt-6">
                        Available balance
                      </p>
                    </div>
                  </button>
                )}
              </div>
            </div>

            <div className="mb-18">
              <div className="row mb-11 justify-content-center">
                <div className="col-10 col-sm-4">
                  {loading ? (
                    <Skeleton width={400} height={60} />
                  ) : (
                    <a
                      href={process.env.GATSBY_NETWORK_ID === "testnet" ? "https://wallet.testnet.near.org/buy" : "https://wallet.near.org/buy"}
                      target="_bank"
                      className={`btn btn-${gContext.header.variant} btn-xl text-uppercase font-size-3 w-100`}
                    >
                      <span className="mr-2">Deposit</span>
                      <i className="fa fa-external-link-alt"></i>
                    </a>
                  )}
                </div>
              </div>
            </div >
          </div >
        </div >
      </PageWrapper >
    </>
  );
};

export default DashboardAdminWallet;
